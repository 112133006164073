header {
    display: flex;
    align-items: center;
    height: 130px;
    width: 100%;
    justify-content: space-between;
}

#header h1 {
    font-size: 2.5rem;
    margin-left: 40px;
    display: inline-block;
}

#header h1 a {
    text-decoration: none;
    color: gray;
}

#header h1 a:hover {
    color: black;
}

#header nav {
    display: flex;
    height: 100%;
    width: 20%;
    margin-right: 40px;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
}

#header .nav-links {
    font-size: 1.5rem;
    text-decoration: none;
    color: gray;
    width: auto;
}

#header .current-link {
    color: black;
    font-weight: bold;
}

#header .nav-links:hover {
    color: rgb(51, 51, 51);
}