.projectDescriptionPage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.projectDescriptionPage .projectDescription {
    max-width: 600px;
    min-width: 300px;
    margin: 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 980px) {
    .projectDescriptionPage .projectDescription {
        top: 20px;
        position: -webkit-sticky;
        position: sticky;
    }
}

.projectDescriptionPage .projectDescription h1 {
    text-align: center;
}

.projectDescriptionPage .projectDescription p {
    width: 85%;
    margin: 5px;
}

.projectDescriptionPage .projectDescription .projectLinks {
    display: flex;
    flex-direction: row;
}

.projectDescriptionPage .projectDescription .projectLinks div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.projectDescriptionPage .projectDescription .projectLinks a {
    background-color: black;
    text-decoration: none;
    color: white;
    padding: 10px 20px;
    margin-right: 20px;
    cursor: pointer;
}

.projectDescriptionPage .projectImageContainer {
    width: 250px;
    margin: 20px 40px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.projectDescriptionPage .projectImageContainer img {
    width: 100%;
    margin-top: 20px;
}