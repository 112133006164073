html {
    height: 100%;
}

body {
    height: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: white;
}

#root {
    height: 100%;
}

#Index .selector {
    display: flex;
    height: 100px;
    width: 65%;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
}

#Index .selector button {
    background-color: white;
    border: 1px solid gray;
    color: gray;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#Index .selector button:hover {
    border: 1.5px solid black;
    color: black;
}

#Index .selector .selected {
    border: 1.5px solid black;
    color: black;
}

#Index .projects {
    width: 65%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

#Index .project {
    width: 320px;
    margin: 30px 40px 0px 40px;
}

#Index .project a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
}

#Index .project a img {
    width: calc(100% - 2px);
    height: auto;
    border: 1px solid gray;
}

#Index .project a h3 {
    height: 50px;
    width: 100%;
    text-align: center;
    color: gray;
}

@media only screen and (max-width: 600px) {
    #Index .selector {
        display: none;
    }
}